import CryptoJS from "crypto-js";
import moment from "moment";
import share from "./share";
import { toUrlChain } from "./icon-chains";
const SALT = "Thuandv";

export const navProjects = [
	{ key: "all", link: "/all", title: "All", icon: "grid3x3-gap", items: [] },
	{ key: "active", link: "/", title: "Active", icon: "clipboard-check", items: [] },
	{ key: "upcoming", link: "/upcoming", title: "UpComing", icon: "wallet", items: [] },
	{ key: "ended", link: "/ended", title: "Ended", icon: "heart", items: [] },
];

export const mainMenus = [
	{ key: "home", link: "/", title: "Projects", icon: "clipboard-check", items: ["project-detail"] },
	{ key: "research", link: "/research", title: "Research", icon: "wallet", items: ["research-detail"] },
	{ key: "news", link: "/news", title: "News", icon: "heart", items: ["news-detail"] },
	{ key: "watch", link: "/watch", title: "Watch", icon: "clock", items: ["w-project-detail"] },
	{ key: "about-us", link: "/about-us", title: "About Us", icon: "heart", items: [] },
];

export const navList = [
	{
		name: "Projects",
		key: "project",
		childrens: [...navProjects, { key: "watch", link: "/watch", title: "Watch", icon: "clock" }],
	},
	{
		name: "Admin",
		key: "admin",
		childrens: [
			{ key: "projects", link: "/projects", title: "Projects", icon: "stack" },
			{ key: "stages", link: "/stage", title: "Stage", icon: "bookmark-star" },
			{ key: "chains", link: "/chains", title: "Chains", icon: "bar-chart-steps" },
			{ key: "categories", link: "/categories", title: "Categories", icon: "app-indicator" },
			{ key: "admin-news", link: "/news", title: "News", icon: "newspaper" },
			{ key: "admin-research", link: "/research", title: "Research", icon: "search" },
			{ key: "admin-users", link: "/users", title: "Users", icon: "people" },
		],
	},
];

function formatNumberShort(number) {
	const absNumber = Math.abs(number);
	if (absNumber >= 1e12) {
		return (number / 1e12).toFixed(1) + "T";
	} else if (absNumber >= 1e9) {
		return (number / 1e9).toFixed(1) + "B";
	} else if (absNumber >= 1e6) {
		return (number / 1e6).toFixed(1) + "M";
	} else if (absNumber >= 1e3) {
		return (number / 1e3).toFixed(1) + "K";
	} else {
		return `${number}`;
	}
}

function toSlug(name) {
	return name
		.toLowerCase()
		.replace(/[^\w\s-]/g, "")
		.trim()
		.replace(/\s+/g, "-")
		.replace(/--+/g, "-")
		.substring(0, 64);
}

function toProjectDate({ endAt, startAt }, key) {
	switch (key) {
		case "active":
			if (endAt) return `${moment(endAt).toNow(true)}`;
			return "TBA";

		case "upcoming":
			if (startAt) return `${moment(startAt).fromNow()}`;
			return "TBA";

		case "ended":
			if (endAt) return `Ended ${moment(endAt).fromNow()}`;
			return "TBA";
		default:
			break;
	}
	return "TBA";
}

function formatNumber(val, fix = 2) {
	if (typeof val === "number") return val.toFixed(fix);
	return val;
}

function fnTruncate(fullStr, strLen) {
	if (!fullStr || fullStr.length <= strLen) return fullStr;
	return `${fullStr.substr(0, strLen)}...`;
}

export function getDomainName(inputUrl) {
	try {
		const parsedUrl = new URL(inputUrl);
		const hostname = parsedUrl.hostname.split(/./);
		if (hostname.length > 1) {
			if (hostname[0] === "www" && hostname.length > 1) {
				return hostname[1];
			}
			return hostname[0];
		}

		return parsedUrl.hostname;
	} catch (error) {
		return inputUrl;
	}
}

export function removeAllQueryParams(inputUrl) {
	try {
		let parsedUrl = new URL(inputUrl);
		parsedUrl.search = "";
		parsedUrl.query = "";
		return parsedUrl.toString();
	} catch (error) {
		return inputUrl;
	}
}

function getYearFromString(str) {
	const yearPattern = /\b\d{4}\b/;
	const match = str.match(yearPattern);
	return match ? match[0] : null;
}

function toStartAt(startAt, endAt) {
	if (startAt && endAt) {
		if (!getYearFromString(startAt)) {
			const yearEnd = getYearFromString(endAt);
			if (yearEnd) {
				startAt = `${startAt} ${yearEnd}`;
			}
		}
	}

	return startAt;
}

export default {
	toStartAt,
	getYearFromString,
	getDomainName,
	removeAllQueryParams,
	formatNumberShort,
	formatNumber,
	toSlug,
	toProjectDate,
	toCapitalize: (capitalizedStr) => {
		if (!capitalizedStr) return "";
		return capitalizedStr.charAt(0).toUpperCase() + capitalizedStr.slice(1);
	},
	googleLogin: {
		client_id: "22930880181-qae08270e9uok1g3rbr06c5rr3v6dkh7.apps.googleusercontent.com",
		project_id: "ido-calendar",
		auth_uri: "https://accounts.google.com/o/oauth2/auth",
		token_uri: "https://oauth2.googleapis.com/token",
		auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
		client_secret: "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",
		redirect_uris: ["http://localhost"],
	},
	decode: (data) => {
		const bytes = CryptoJS.AES.decrypt(data, SALT);
		const jsonStr = bytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(jsonStr);
	},
	navList,
	share,
	navProjects,
	fnTruncate,
	toUrlChain,
};
