<template>
	<div class="vp-wrapper d-flex flex-column gap">
		<div class="vp-row">
			<b-input-group>
				<template #prepend>
					<b-input-group-text
						style="color: #16a34a; background: transparent; min-width: 7rem; text-align: center"
						>Unlocked</b-input-group-text
					>
				</template>
				<b-form-input
					class="text-input"
					v-model="form.unlocked"
					@input="(val) => onInput('unlocked', val)"
				></b-form-input>
			</b-input-group>
		</div>
		<div class="vp-row">
			<b-input-group prepend="Locked">
				<template #prepend>
					<b-input-group-text
						style="color: #e5e7eb; background: transparent; min-width: 7rem; text-align: center"
						>Locked</b-input-group-text
					>
				</template>
				<b-form-input
					v-model="form.locked"
					@input="(val) => onInput('locked', val)"
					class="text-input"
				></b-form-input>
			</b-input-group>
		</div>
		<div class="vp-row">
			<b-input-group prepend="Untracked">
				<template #prepend>
					<b-input-group-text
						style="color: #f87171; background: transparent; min-width: 7rem; text-align: center"
						>Untracked</b-input-group-text
					>
				</template>
				<b-form-input
					v-model="form.untracked"
					@input="(val) => onInput('untracked', val)"
					class="text-input"
				></b-form-input>
			</b-input-group>
		</div>
	</div>
</template>

<script setup>
import { defineProps, reactive, defineEmits } from "vue";
const props = defineProps(["item"]);
const emit = defineEmits(["onUpdate"]);

const onInput = (key, value) => {
	emit("onUpdate", { ...props.item, [key]: value });
};

const form = reactive(props.item);

// watch(
// 	() => props.item.unlocked,
// 	(newVal) => {
// 		form.unlocked = newVal;
// 	}
// );
</script>

<style lang="scss" scoped>
.vp-wrapper {
	&.gap {
		gap: 1rem;
	}
}
</style>
