<template>
	<div class="c-wrap">
		<div class="text-left form-body px-4 py-2 mx-2">
			<form @submit.prevent="onSubmit" @reset.prevent="onReset" class="w-100">
				<b-tabs content-class="mt-3 w-100" id="my-tab">
					<template #tabs-end>
						<div class="ml-auto">
							<b-button
								:disabled="isBusy"
								class="px-4 btn-ido-primary md btn-white p-font mb-2"
								type="submit"
								variant="primary"
							>
								<b-icon-three-dots animation="cylon" v-if="isBusy" />
								<b-icon-save v-else />
								Save
							</b-button>
						</div>
					</template>

					<b-tab title="Project info" :active="route.hash === '#' || $route.hash === ''">
						<!-- project info -->
						<b-row>
							<!-- <b-col cols="12" class="h4 p-font">Project info</b-col> -->
							<b-col cols="12" md="6">
								<b-form-group label="Name" label-for="txt-name" class="p-font">
									<b-form-input
										class="text-input"
										id="txt-name"
										placeholder="Name"
										v-model="form.name"
										:readonly="!!route.params.id"
										trim
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="6">
								<b-form-group label="Symbol" label-for="txt-symbol" class="p-font">
									<b-form-input
										id="txt-symbol"
										placeholder="Symbol"
										class="text-input"
										:readonly="!!route.params.id"
										v-model="form.symbol"
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<label>Category</label>
								<tags-input
									:tags="form.categories"
									placeholder="Enter some categories"
									@on-tags-changed="(tags) => handleChangeTag('categories', tags)"
								/>
							</b-col>
							<b-col cols="12" md="4">
								<div label="Chain" label-for="txt-chain" class="p-font">
									<label>Chain</label>
									<tags-input
										placeholder="Enter some chains"
										:tags="form.chains"
										@on-tags-changed="(tags) => handleChangeTag('chains', tags)"
									/>
								</div>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group label="Stage" label-for="txt-stage" class="p-font">
									<b-form-input
										id="txt-stage"
										placeholder="Stage"
										v-model="form.stage"
										class="text-input"
										list="ls-stage"
										trim
									></b-form-input>
									<b-form-datalist
										id="ls-stage"
										text-field="name"
										class="p-font"
										:options="storeCat.stages"
									></b-form-datalist>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group label="Total Supply" label-for="txt-msupply" class="p-font">
									<b-form-input
										id="txt-msupply"
										placeholder="Total Supply"
										class="text-input"
										v-model="form.supply"
										trim
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group label="Hard Cap" label-for="txt-hardcap" class="p-font">
									<b-form-input
										id="txt-hardcap"
										placeholder="Hard Cap"
										class="text-input"
										v-model="form.hardtcap"
										trim
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group label="Soft Cap" class="p-font" label-for="txt-softcap">
									<b-form-input
										id="txt-softcap"
										placeholder="Soft Cap"
										class="text-input"
										v-model="form.softcap"
										trim
									></b-form-input>
								</b-form-group>
							</b-col>

							<b-col cols="12" md="6">
								<b-form-group label="Website" label-for="txt-website" class="p-font">
									<b-form-input
										id="txt-website"
										placeholder="Website"
										class="text-input"
										v-model="form.website"
										trim
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="6">
								<b-form-group label="Logo" label-for="txt-logo" class="ido-file">
									<b-form-file
										@change="onLogoUpload"
										id="txt-logo"
										name="file"
										placeholder="Drop Logo"
										accept="image/png, image/gif, image/jpeg, image/*"
										v-model="form.logo"
										trim
									></b-form-file>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="6">
								<b-input-group prepend="Start At" label-for="txt-start-at" class="p-font">
									<!-- startLabel -->
									<b-form-input
										placeholder="Display"
										aria-label="Start At"
										class="text-input"
										v-model="form.startLabel"
									></b-form-input>
									<b-form-input
										type="datetime-local"
										id="txt-start-at"
										name="startAt"
										class="text-input"
										placeholder="Start At"
										v-model="form.startAt"
										trim
									>
									</b-form-input>
								</b-input-group>
							</b-col>
							<b-col cols="12" md="6">
								<b-input-group prepend="End At" label-for="txt-end-at" class="p-font">
									<!-- startLabel -->
									<b-form-input
										placeholder="Display"
										aria-label="End At display"
										class="text-input"
										v-model="form.endLabel"
									></b-form-input>
									<b-form-input
										type="datetime-local"
										id="txt-end-at"
										name="endAt"
										class="text-input"
										placeholder="End At"
										v-model="form.endAt"
										trim
									>
									</b-form-input>
								</b-input-group>
								<!-- <b-form-group class="p-font" label="End At" label-for="txt-end-at">
									<b-form-input
										type="datetime-local"
										id="txt-end-at"
										class="text-input"
										placeholder="End At"
										v-model="form.endAt"
										trim
									></b-form-input>
								</b-form-group> -->
							</b-col>
							<b-col cols="12">
								<b-form-group label="Description" label-for="txt-description">
									<b-form-textarea
										id="txt-description"
										placeholder="Description"
										v-model="form.description"
										rows="3"
										class="text-input"
										max-rows="6"
										trim
									></b-form-textarea>
								</b-form-group>
							</b-col>
						</b-row>
						<!-- Token sale info -->
						<b-row class="pb-3">
							<b-col cols="12" class="h4">Token Sale Detail</b-col>
							<template v-for="op in tokenOption" :key="op.key">
								<b-col cols="12" :md="op.md || 6" class="pb-3" v-if="op.key === 'launchpad'">
									<label class="p-font">{{ op.title }} (Optional)</label>
									<b-input-group :label-for="`sale-${op.key}`" class="ido-file">
										<template #prepend v-if="form.saleInfo?.launchpad?.lp_logo">
											<b-input-group-text class="bg-transparent" style="border-color: #ffffff33">
												<b-avatar
													size="1.5rem"
													:src="toUrlBase(form.saleInfo.launchpad.lp_logo)"
													badge="x"
													badge-top
												/>
											</b-input-group-text>
										</template>
										<b-form-file
											class="text-input p-font"
											@change="onLaunchpadLogoUpload"
											id="txt-lp-logo"
											name="file"
											placeholder="Drop Launchpad Logo"
											accept="image/png, image/gif, image/jpeg, image/*"
											type="file"
											trim
										>
										</b-form-file>
										<b-form-input
											class="text-input p-font"
											:id="`sale-${op.key}`"
											placeholder="Title"
											:type="op.url"
											v-model="form.saleInfo.launchpad.lp_title"
											trim
										>
										</b-form-input>
										<b-form-input
											class="text-input p-font"
											placeholder="Link"
											:type="op.url"
											v-model="form.saleInfo.launchpad.lp_link"
											trim
										>
										</b-form-input>
									</b-input-group>
								</b-col>
								<b-col v-else cols="12" :md="op.md || 6" class="pb-3">
									<label class="p-font">{{ op.title }} (Optional)</label>
									<b-input-group :label-for="`sale-${op.key}`">
										<b-form-input
											class="text-input p-font"
											:id="`sale-${op.key}`"
											:placeholder="op.title"
											:type="op.url"
											v-model="form.saleInfo[op.key]"
											trim
										>
										</b-form-input>
									</b-input-group>
								</b-col>
							</template>
						</b-row>

						<!-- Social link -->
						<b-row>
							<b-col cols="12" class="h4 mt-0">
								<SocialModal myId="-info" @onSuccess="onSocialModalSuccess" :sList="sList" />
								Social info <b-icon-plus-circle class="ml-2 m-cursor" @click="onInfoShow($bvModal)" />
							</b-col>
							<template v-for="[cKey, socialLink] of Object.entries(form.socialLink)" :key="cKey">
								<b-col cols="12" md="6" v-if="socialLink">
									<b-input-group
										:label="`Redit (Optional)`"
										:label-for="`txt-${cKey}`"
										class="mt-3 ido-group-input"
									>
										<template #prepend>
											<b-input-group-text>
												<b-avatar
													v-if="icons[cKey]?.img"
													size="1.5rem"
													:src="icons[cKey]?.img"
													:text="icons[cKey]?.text"
													:style="`background: ${icons[cKey].color}`"
													v-b-tooltip.hover
													:title="utils.toCapitalize(cKey)"
												/>
												<b-icon
													v-b-tooltip.hover
													:title="utils.toCapitalize(cKey)"
													v-else-if="icons[cKey]?.icon"
													:icon="icons[cKey]?.icon"
													:color="icons[cKey]?.color"
												/>
												<b-avatar
													v-else
													size="1.5rem"
													:text="icons[cKey]?.text"
													:style="`background: ${icons[cKey]?.color}`"
													v-b-tooltip.hover
													:title="utils.toCapitalize(cKey)"
												/>
											</b-input-group-text>
										</template>
										<b-form-input
											:id="`txt-${cKey}`"
											type="url"
											class="text-input"
											:placeholder="`Link ${icons[cKey]?.text || ''} (Optional)`"
											v-model="form.socialLink[cKey]"
											trim
										>
										</b-form-input>
									</b-input-group>
								</b-col>
							</template>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12" md="6" v-if="auth.user?.isAdmin">
								<div>
									<label class="mt-1 p-font">Status</label>
									<v-select
										class="w-100 ido-select"
										v-model="form.status"
										:options="options"
									></v-select>
								</div>
							</b-col>
							<b-col cols="12" md="6">
								<div>
									<label class="p-font">Source link</label>
									<b-input-group
										label="Source link"
										label-for="txt-source_link"
										class="c-input-group"
									>
										<b-input
											id="txt-source_link"
											placeholder="Source link"
											class="text-input"
											v-model="form.source_link"
											trim
										></b-input>
										<template #append>
											<b-input-group-text @click="share.openLink(form.source_link)">
												<strong class="text-danger m-cursor">
													<b-icon-box-arrow-in-up-right color="#fff" />
												</strong>
											</b-input-group-text>
										</template>
									</b-input-group>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="12" md="6">
								<label class="ido-text-primary">Tags</label>
								<v-select
									class="w-100 ido-select multiple"
									v-model="form.tags"
									multiple
									:options="['Hot']"
									label="name"
									placeholder="Add Tags"
								></v-select>
							</b-col>
							<b-col cols="12" md="6">
								<label class="ido-text-primary">Project Type</label>
								<v-select
									class="w-100 ido-select"
									v-model="form.ptype"
									:options="pProjects"
									label="title"
									value="key"
									placeholder="Project Type"
								></v-select>
							</b-col>
						</b-row>
						<b-row class="about-page">
							<b-col cols="12" class="h4 my-2 p-font">About Project</b-col>
							<b-col cols="12">
								<div class="editor">
									<QuillEditor v-model:content="form.about" content-type="html" theme="snow" />
								</div>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab
						v-if="isEdit"
						title="Allocations"
						class="w-100"
						:active="route.hash === '#allocation'"
						to="#allocation"
					>
						<b-row>
							<b-col cols="12">
								<div class="d-flex pb-2">
									<label class="mt-2 p-font" v-if="false">Allocations</label
									><b-btn
										variant="primary"
										class="ml-auto px-3 btn-ido-primary outline"
										@click="addAllocation($event, null)"
									>
										<b-icon-plus aria-hidden="true" />Add Allocation
									</b-btn>
								</div>
								<b-table
									id="tb-allocation"
									striped
									hover
									:items="form.tokenomics"
									:fields="state.fields"
									show-empty
									class="ido-table"
									thead-class="tb-thead"
									tbody-class="tb-tbody"
									responsive
									:key="({ title }) => title"
								>
									<template #table-busy>
										<tb-loading table="true" />
									</template>
									<template #empty>
										<tb-empty />
									</template>
									<template #cell(action)="{ item }">
										<div class="tb-action">
											<b-icon-pencil-square
												variant="info"
												class="a-btn"
												@click="addAllocation($event, item)"
											/>
											<span>|</span>
											<b-icon-trash class="a-btn" variant="danger" :id="`row-${item.title}`" />
											<b-popover :target="`row-${item.title}`" title="Confirm" triggers="click">
												<div class="text-center">
													<div>Are you sure you want to delete this item?</div>
													<div class="d-flex mt-2 justify-content-end">
														<b-btn
															variant="danger"
															size="sm"
															class="px-2"
															@click="$root.$emit('bv::hide::popover')"
															>Cancel</b-btn
														>
														<b-btn
															@click="onDelete($event, item, $root)"
															variant="info"
															size="sm"
															class="px-4 ml-2"
															>OK</b-btn
														>
													</div>
												</div>
											</b-popover>
										</div>
									</template>
								</b-table>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab v-if="isEdit" title="Investors" :active="route.hash === '#inv'" to="#inv">
						<div class="d-flex pb-2">
							<label class="mt-2 p-font" v-if="false">Allocations</label
							><b-btn
								variant="primary"
								class="ml-auto px-3 btn-ido-primary outline"
								v-b-toggle.sb-inv-add
							>
								<b-icon-plus aria-hidden="true" />Add Investor
							</b-btn>
							<InvestorAdd
								@reset="onReset"
								@onSuccess="onSuccessInvestor"
								:add="sInvestor?.item"
								:item="sInvestor.item"
							/>
						</div>
						<div class="w-100">
							<b-table
								id="tb-investor"
								striped
								hover
								:items="form.investors"
								:fields="sInvestor.fields"
								show-empty
								class="ido-table"
								thead-class="tb-thead"
								tbody-class="tb-tbody"
								responsive
								:key="({ title }) => title"
							>
								<template #table-busy>
									<tb-loading table="true" />
								</template>
								<template #empty>
									<tb-empty />
								</template>
								<template #cell(socials)="{ item }">
									<div v-if="!item.socials">-</div>
									<div v-else class="lbl-social">
										<template v-for="(k, v) in item.socials" :key="k">
											<a :href="k" target="_brank">{{ v }}</a>
										</template>
									</div>
								</template>
								<template #cell(title)="{ item: { title, logo } }">
									<div>
										<b-img class="m-icon" :src="toUrlBase(logo)" />
										<span> {{ title }} </span>
									</div>
								</template>
								<template #cell(action)="{ item }">
									<div class="tb-action">
										<b-icon-pencil-square
											variant="info"
											class="a-btn"
											@click="addInvestor($root, item)"
										/>
										<span>|</span>
										<b-icon-trash class="a-btn" variant="danger" :id="`row-${item.title}`" />
										<b-popover :target="`row-${item.title}`" title="Confirm" triggers="click">
											<div class="text-center">
												<div>Are you sure you want to delete this item?</div>
												<div class="d-flex mt-2 justify-content-end">
													<b-btn
														variant="danger"
														size="sm"
														class="px-2"
														@click="$root.$emit('bv::hide::popover')"
														>Cancel</b-btn
													>
													<b-btn
														@click="onDelInvestor($event, item, $root)"
														variant="info"
														size="sm"
														class="px-4 ml-2"
														>OK</b-btn
													>
												</div>
											</div>
										</b-popover>
									</div>
								</template>
							</b-table>
						</div></b-tab
					>

					<b-tab title="Token Sales" v-if="isEdit">
						<TokenSales :value="form.tokenSales" @update="onUpdateTokenSale" v-model="form.tokenSales" />
					</b-tab>
					<b-tab title="Vesting Progress" v-if="isEdit">
						<VestingProgress :item="formVP" @onUpdate="onVestingProgressUpdate" />
					</b-tab>
				</b-tabs>
			</form>
		</div>

		<b-sidebar v-model="state.show" no-close-on-backdrop right variant="dark" backdrop shadow id="sb-add">
			<template #header="{ hide }">
				<div class="w-100 d-flex justify-content-between align-items-center align-self-center">
					<div class="text-capitalize">{{ state?.item ? "Edit" : "Add New" }}</div>
					<b-icon-x @click="hide" class="m-action" />
				</div>
			</template>
			<template #default>
				<AllocationAdd @onSuccess="onSuccessAdd" :item="state.item" :add="!state?.item" />
			</template>
		</b-sidebar>
	</div>
</template>

<script setup>
import { computed, nextTick, onBeforeUpdate, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { keyBy } from "lodash";
import share from "@/utils/share";
import AllocationAdd from "./AllocationAdd";
import TokenSales from "./TokenSales";
import InvestorAdd from "./InvestorAdd";
import SocialModal from "./SocialModal";
import VestingProgress from "./VestingProgress.vue";
import { keys } from "lodash";
import utils from "@/utils";
import { projectApi, mediaApi } from "@/api";
import moment from "moment";
import { useAuth, useFilter, useToast, useUtils } from "@/uses";

const utilsNav = useUtils();

const toast = useToast();

const route = useRoute();
const router = useRouter();
const auth = useAuth();
const storeCat = useFilter();

const options = ["Create", "Approve", "Reject", "BOT"];

const state = reactive({
	count: 2,
	show: false,
	item: null,
	fields: [
		{
			key: "title",
			label: "Title",
			class: "text-left",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "percentage",
			label: "Percentage",
			class: "text-right",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "total",
			label: "Total",
			class: "text-right",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "description",
			label: "Description",
			class: "text-left",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "action",
			label: "Actions",
			class: "text-center",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
	],
});

function onUpdateTokenSale(vals) {
	form.tokenSales = vals;
}

const sList = computed(() => {
	if (form.socialLink) {
		return keys(form.socialLink);
	}
	return [];
});

const isEdit = true;
// const isEdit = computed(() => !!route.params.id);

const icons = keyBy(share.socialsList, "value");

const sInvestor = reactive({
	busy: true,
	item: null,
	fields: [
		{
			key: "title",
			label: "Title",
			class: "text-left",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "socials",
			label: "Socials",
			class: "text-left",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
		{
			key: "action",
			label: "Actions",
			class: "text-center",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
	],
});

const pProjects = [
	{
		key: "active",
		title: "Active",
	},
	{
		key: "upcoming",
		title: "UpComing",
	},
	{
		key: "ended",
		title: "Ended",
	},
];

const handleChangeTag = (key, tags) => {
	form[key] = tags;
};

const onVestingProgressUpdate = (data) => {
	formVP.locked = data.locked;
	formVP.unlocked = data.unlocked;
	formVP.untracked = data.untracked;
};

const formVP = reactive({
	unlocked: "",
	locked: "",
	untracked: "",
});

const form = reactive({
	key: "",
	name: "",
	slug: "",
	symbol: "",
	website: "",
	chain: "",
	chains: [],
	categories: [],
	category: "",
	stage: "ICO",
	ptype: "",
	hardtcap: "",
	softcap: "",
	description: "",
	logo: "",
	status: "Create",
	startAt: null,
	endAt: null,
	socialLink: {},
	source_link: "",
	saleInfo: {
		tType: "",
		launchpad: { lp_link: "", lp_title: "", lp_logo: "" },
		price: "",
		link: "",
		supply: "",
	},
	supply: "",
	about: "",
	tokenomics: [],
	investors: [],
	tokenSales: [
		// {
		// 	tStage: "IDO",
		// 	tStartAt: "",
		// 	tEndAt: "",
		// 	tRaise: "$1.5M",
		// 	tSupply: "100000000",
		// 	tlpName: "",
		// 	tlpLink: "",
		// 	tPrice: "$0.015",
		// },
		// {
		// 	tStage: "IEO",
		// 	tStartAt: "Jun 03, 2024",
		// 	tEndAt: "Jun 06, 2024",
		// 	tRaise: "",
		// 	tSupply: "300000000",
		// 	tlpName: "OKX Jumpstart",
		// 	tlpLink: "https://www.okx.com/jumpstart#from=home",
		// 	tPrice: "$0.015",
		// },
	],
	tags: [],
	vestingProgress: {},
	endLabel: "",
	startLabel: "",
});

const isBusy = ref(false);

const logoFile = ref(null);

async function onLogoUpload($event) {
	$event.preventDefault();
	logoFile.value = $event.target.files[0];
}

async function onLaunchpadLogoUpload($event) {
	$event.preventDefault();
	const file = $event.target.files[0];
	if (file) {
		try {
			const { data } = await mediaApi.upload(file, { f: "launchpads" });
			if (data) {
				form.saleInfo.launchpad.lp_logo = data;
				toast.success("Upload successfully!");
			}
		} catch ({ message }) {
			toast.warn(message);
		}
	}
}

async function onSubmit($event) {
	$event.preventDefault();
	isBusy.value = true;
	const params = {};
	if (!form.name) {
		toast.warn("Name is not empty!");
		return;
	}
	let vp1 = null;
	let isAdd1 = false;
	for (let key in form) {
		switch (key) {
			case "chains":
			case "categories":
				if (form[key])
					params[key] = form[key].map((name) => ({
						key: utils.toSlug(name),
						name,
					}));
				break;
			case "chain":
			case "category":
			case "stage":
				if (form[key])
					params[key] = {
						key: utils.toSlug(form[key]),
						name: form[key],
					};
				break;
			case "ptype":
				if (typeof form.ptype == "object") {
					params[key] = `${form.ptype?.key}`.toLowerCase();
				} else if (typeof form.ptype == "string") {
					params[key] = `${form.ptype}`.toLowerCase();
				}

				if (!["", "active", "upcoming", "ended"].includes(params[key])) {
					params[key] = "";
				}
				break;
			case "socialLink":
				if (form.socialLink) {
					params.socialLink = {};
					for (let _key in form.socialLink) {
						if (form.socialLink[_key]) {
							params.socialLink[_key] = form.socialLink[_key];
						}
					}
				}
				break;
			case "vestingProgress":
				params[key] = [];
				for (const [k1, v1] of Object.entries(formVP)) {
					if (!vp1) vp1 = {};
					vp1[k1] = v1;
					if (v1 && !isAdd1) isAdd1 = true;
				}

				if (isAdd1 && vp1) params[key] = [vp1];
				break;
			default:
				params[key] = form[key];
				break;
		}
	}
	if (!form.key) {
		form.key = utils.toSlug(`${form.symbol} ${form.name}`);
	}

	if (!form.slug) {
		form.slug = utils.toSlug(`${form.symbol} ${form.name}`);
	}
	if (form.socialLink) {
		for (let _key in form.socialLink) {
			if (!form.socialLink[_key]) delete form.socialLink[_key];
		}
	}
	try {
		let rs;
		if (logoFile.value) {
			try {
				const { data } = await mediaApi.upload(logoFile.value, null);
				if (data) {
					form.logo = data;
					params.logo = data;
					logoFile.value = null;
				}
			} catch ({ message }) {
				toast.warn(message);
			}
		}
		if (route.params.id) {
			rs = await projectApi.edit(route.params.id, params);
		} else {
			rs = await projectApi.add(params);
		}
		if (rs) {
			toast.success();
			await new Promise((r) => setTimeout(r, 500));
			router.push({ name: "projects" });
		}
	} catch ({ message }) {
		if (message) toast.warn(message);
	} finally {
		isBusy.value = false;
	}
}

function onReset(e) {
	if (e) e.preventDefault();
	sInvestor.item = null;
}

function toFloat(val) {
	if (val) return parseFloat(`${val}`.replace(/[^0-9.]/g, ""));
	return "";
}

function onDelInvestor(event, { title }, $root) {
	if ($root) $root.$emit("bv::hide::popover");
	form.investors = form.investors.filter((t) => t.title !== title);
}

function onSuccessInvestor(v, hide) {
	form.investors = form.investors.filter((t) => t.title !== v.title);
	form.investors.push({ ...v });
	sInvestor.item = null;
	hide();
}

function addInvestor($root, item) {
	$root.$emit("bv::toggle::collapse", "sb-inv-add");
	sInvestor.item = item;
}

function onSuccessAdd(item, add) {
	if (item.title && item.percentage) {
		item.percentage = toFloat(item.percentage);
		state.show = false;
		if (add) {
			form.tokenomics.push({ ...item });
		} else {
			form.tokenomics = form.tokenomics.map((it) => {
				if (item.title === it.title) {
					it = { ...item };
				}
				return it;
			});
			// form.tokenomics.push(item);
		}
	}
}

function addAllocation(evt, item) {
	state.item = item;
	state.show = true;
}

function onDelete($evt, item, $root) {
	if ($root) $root.$emit("bv::hide::popover");
	form.tokenomics = form.tokenomics.filter(({ title }) => item.title !== title);
}

function onInfoShow($modal) {
	$modal.show("modal-socials-info");
}

function onSocialModalSuccess(vals) {
	for (let inx = 0; inx < vals.length; inx++) {
		if (form.socialLink) {
			form.socialLink[vals[inx]] = form.socialLink[vals[inx]] || "";
		}
	}
}

onMounted(async () => {
	if (route.params.id) {
		const { data } = await projectApi.byId(route.params.id);
		for (let key in form) {
			if (Object.hasOwnProperty.call(form, key)) {
				switch (key) {
					case "chains":
					case "categories":
						form[key] = [];
						if (data[key]) {
							form[key] = data[key].map(({ name }) => name);
						}

						break;
					case "chain":
					case "category":
					case "stage":
						form[key] = data[key]?.name;
						break;

					case "startAt":
					case "endAt":
						if (data[key]) {
							form[key] = moment(data[key]).format("YYYY-MM-DDTHH:mm"); // 2024-03-12T23:55
						}
						break;
					case "socialLink":
						if (data.socialLink) {
							form.socialLink = {};
							for (let _key in data.socialLink) {
								if (data.socialLink[_key]) {
									form.socialLink[_key] = data.socialLink[_key];
								}
							}
						}
						break;
					case "saleInfo":
						if (data.saleInfo) {
							form[key] = data[key];
							if (data[key]?.launchpad) {
								if (typeof data[key]?.launchpad === "string") {
									form[key].launchpad = {
										lp_title: utils.getDomainName(data[key]?.launchpad),
										lp_link: data[key]?.launchpad,
									};
								}
							} else {
								form[key].launchpad = { lp_link: "", lp_title: "" };
							}
						}
						break;

					default:
						if (key === "tokenSales") {
							await nextTick();
						}
						form[key] = data[key];
						break;
					case "ptype":
						if (data?.ptype && typeof data.ptype === "string") {
							form.ptype = pProjects.find(({ key }) => key == data.ptype);
						}
						break;

					case "vestingProgress":
						if (data?.vestingProgress && data.vestingProgress.length > 0)
							onVestingProgressUpdate(data.vestingProgress[0]);
						break;
				}
			}
		}
	}
});

onBeforeUpdate(() => {
	utilsNav.updateBreadcrumb();
});
// -------

const tokenOption = [
	{ key: "tType", title: "Token Type", md: 6, type: "text" },
	{ key: "supply", title: "Tokens for Sale", md: 6, type: "text" },
	{ key: "price", title: "Token Sale Price", md: 6, type: "text" },
	{ key: "link", title: "Token Sale Page", md: 6, type: "url" },
	{ key: "launchpad", title: "Launchpad", md: 12, type: "url" },
];
</script>

<style lang="scss" scoped>
.c-wrap {
	flex: 1;
	.form-body {
		height: 100%;
		display: flex;
		flex: 1;
		overflow-y: auto;
		border-radius: 2.5rem;
		border: 2px solid var(--border-color);
		#tb-allocation,
		#tb-investor {
			.tb-action {
				display: flex;
				gap: 0.5rem;
				justify-content: center;
				align-items: center;
				.a-btn {
					cursor: pointer;
				}
			}
		}
	}

	.tb-toolbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		justify-items: center;
	}
	.about-page {
		margin-top: 16px;
		padding-bottom: 16px;
		.editor {
			min-height: 50px;
		}
	}
	.tb-foolter {
		gap: 16px;
		align-items: center;
		justify-content: flex-end;
		display: flex;
	}
	#tb-investor {
		.m-icon {
			height: 2rem;
			width: auto;
			margin-right: 1rem;
		}
		.lbl-social {
			a {
				text-transform: capitalize;
				margin-right: 0.5rem;
			}
		}
	}
}
</style>
