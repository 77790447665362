<template>
	<div
		v-if="props.items && props.items?.length > 0"
		class="d-flex justify-content-center align-items-center chain-wapper"
	>
		<template v-if="props.items.length > 1">
			<b-avatar-group size="1.5rem" class="items-group">
				<template v-for="item in props.items" :key="`chain-li-${item.key}`">
					<b-img
						v-if="item?.logo"
						class="ic-item"
						:src="toUrlBaseSave(item?.logo, item?.name)"
						v-b-tooltip.hover
						:title="item?.name"
					/>
					<b-img v-else class="ic-item" :src="toUrlChain(item?.name)" v-b-tooltip.hover :title="item?.name" />
				</template>
			</b-avatar-group>
		</template>
		<template v-else-if="props?.items?.length === 1">
			<b-img
				v-if="props.items[0]?.logo"
				class="ic-item"
				:src="toUrlBaseSave(props.items[0]?.logo, props.items[0]?.name)"
				v-b-tooltip.hover
				:title="props.items[0]?.name"
			/>
			<b-img
				v-else
				class="ic-item"
				:src="toUrlChain(props.items[0]?.name)"
				v-b-tooltip.hover
				:title="props.items[0]?.name"
			/>
		</template>
	</div>
</template>

<script setup>
import { defineProps } from "vue";
import { toUrlBaseSave } from "@/api";
import { toUrlChain } from "@/utils/icon-chains";

const props = defineProps(["items"]);
</script>

<style lang="scss">
.chain-wapper {
	.ic-item {
		background: #d9d9d9;
		text-align: center;
		min-width: 20px;
		max-width: 24px;
		min-height: 20px;
		max-height: 24px;
		border-radius: 50%;
		color: #0095ff;
		cursor: pointer;
		// font-size: 0.75rem;
		&:hover {
			border: 1px solid var(--border-color);
			box-shadow: #0095ff;
		}
	}
	.items-group {
		.b-avatar-group-inner {
			.ic-item {
				background-color: #641a4a;
				&:hover {
					border-color: #cdcdcd;
					z-index: 100000;
					box-shadow: 0 20px 20px -10px #cdcdcd;
				}
			}
		}
		.ic-item {
			background: #d9d9d9;
			text-align: center;
			min-width: 20px;
			max-width: 24px;
			height: 20px;
			border-radius: 50%;
			color: #0095ff;
			font-size: 0.75rem;
			&:hover {
				border: 1px solid var(--border-color);
				box-shadow: #0095ff;
			}
		}
	}
}
</style>
