<template>
	<div v-if="props.items && Array.isArray(props.items) && props.items.length > 0">
		<div v-if="props.items.length === 1">
			{{ props.items[0]?.name }}
		</div>
		<div
			class="m-cursor"
			v-else-if="props.items.length > 1"
			v-b-tooltip.hover
			:title="props.items.map(({ name }) => name).join(', ')"
		>
			{{ props.items[0]?.name }}...
		</div>
		<div v-else></div>
	</div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["items"]);
</script>
