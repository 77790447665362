<template>
	<div class="vesting-progress mx-3 py-3 mt-2 mb-1 p-font" v-if="props?.items && props?.items.length > 0">
		<div class="title mx-3 py-2 px-1 ido-c-title ido-c-text pb-3">
			<span class="ido-c-title ido-c-text">Vesting Progress</span>
		</div>
		<div class="flex gap-1">
			<div
				class="mt-3 pb-3 px-3 w-100 d-flex gap-1 d-flex flex-column justify-content-between"
				v-for="(item, inx) in props?.items"
				:key="`row-${inx}`"
			>
				<div
					v-for="(col, jnx) in cols"
					:key="`cell-${inx}-${jnx}`"
					class="d-flex flex-row gap-1 justify-content-between vp-row pb-3"
				>
					<div class="d-flex gap justify-content-center align-items-center font-weight-bold p-font">
						<div class="ic-icon" :style="`background-color: ${col.color}`"></div>
						{{ col.text }}
					</div>
					<div class="p-font">{{ item[col.key] || "" }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["items"]);

const cols = [
	{ key: "unlocked", color: "#16a34a", text: "Unlocked" },
	{ key: "locked", color: "#e5e7eb", text: "Locked" },
	{ key: "untracked", color: "#f87171", text: "Untracked" },
];
</script>

<style lang="scss" scoped>
.vesting-progress {
	width: 100%;
	transition: var(--trans-out);
	box-sizing: border-box;
	border: 2px solid rgba(255, 255, 255, 0.2);
	filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05));
	border-radius: 1.5rem;
	.title {
		transition: var(--trans-out);
		font-family: var(--font-family);
		font-style: normal;
		font-weight: 700;
		line-height: 23px;
		display: flex;
		align-items: flex-end;
		color: var(--text-color-primary);
		border-bottom: 1px solid var(--border-color);
	}
	.vp-row {
		border-bottom: 1px solid var(--border-color);
		.ic-icon {
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
		}
	}
}
</style>
