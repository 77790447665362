<template>
	<div class="main-layout">
		<b-navbar class="ido-nav-bar" toggleable="lg" type="dark">
			<b-navbar-brand href="/" class="brand-name">
				<b-img class="logo" src="/logo.png" />
			</b-navbar-brand>
			<b-navbar-toggle target="nav-collapse" class="btn-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav class="main-menu">
					<b-nav-item
						class="menu-item"
						v-for="m in mainMenus"
						@click="onGoMenu(m.key)"
						:key="m.key"
						:class="{ active: route.name === m.key || m.items.includes(route.name) }"
					>
						{{ m.title }}
					</b-nav-item>
				</b-navbar-nav>

				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto group-search">
					<!-- <b-nav-form @submit="store.onFilter"> -->
					<b-nav-form
						class="box-search"
						@submit="(event) => onSubmitSearch(event)"
						v-if="ROUTER_SEACH_WATCH.includes(route.name)"
					>
						<b-input-group>
							<b-input-group-prepend is-text class="icon-search">
								<b-icon-search color="#907C8D" />
							</b-input-group-prepend>
							<b-form-input
								class="input-search"
								placeholder="Search..."
								type="search"
								autocomplete="false"
								v-model="filter.watch.s"
								debounce="100"
								@input="onSearch"
							></b-form-input>
							<!--list="ls-suggests" <b-form-datalist
								id="ls-suggests"
								text-field="name"
								:options="state.suggests"
							></b-form-datalist> -->
						</b-input-group>
					</b-nav-form>
					<b-nav-form
						class="box-search"
						@submit="(event) => onSubmitSearch(event)"
						v-if="ROUTER_SEACH_HOME.includes(route.name)"
					>
						<b-input-group>
							<b-input-group-prepend is-text class="icon-search">
								<b-icon-search color="#907C8D" />
							</b-input-group-prepend>
							<b-form-input
								class="input-search"
								autocomplete="false"
								placeholder="Search..."
								type="search"
								v-model="filter.home.s"
								debounce="100"
								@input="onSearch"
							></b-form-input>
						</b-input-group>
					</b-nav-form>

					<b-nav-item v-if="route.name === 'home'" class="ido-icon" v-b-toggle.home-project-filter>
						<b-icon-sliders />
					</b-nav-item>

					<b-nav-item v-if="route.name === 'watch'" class="ido-icon" v-b-toggle.watch-project-filter>
						<b-icon-sliders />
					</b-nav-item>

					<b-nav-item class="ido-icon" v-if="!auth.isLogin" @click="auth.onToggle(true)">
						<b-icon-person />
					</b-nav-item>

					<b-nav-item-dropdown v-else right no-caret class="ido-icon">
						<template #button-content>
							<b-img-lazy class="ic-user" :src="toUrlBase(auth.user?.avatar)" />
						</template>
						<b-dropdown-item v-if="auth?.user?.isAdmin" @click="onManage" class="p-font">
							Manage
						</b-dropdown-item>
						<b-dropdown-item @click="auth.onLogout" class="p-font">Sign Out</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
		<main class="wrap-layout">
			<router-view />
		</main>
		<login-form />
		<ido-search-form />
		<div class="btn-floating" :class="{ close: state.isFloat }" @click="state.isFloat = !state.isFloat"></div>

		<div :class="`frame-floating ${state.isFloat ? 'active' : ''}`">
			<div class="ac-item border-buttom" @click="onFloatClick('https://t.me/IDOCalendarANN')">
				<b-img class="logo" src="@/assets/socials/telegram.svg" />
				<span class="title">Telegram</span>
			</div>
			<div class="ac-item" @click="onFloatClick('https://forms.gle/sYpvy1uX1d1xALXF9')">
				<b-img class="logo" src="@/assets/imgs/chat.svg" />
				<span class="title">Submit Project</span>
			</div>
		</div>
	</div>
</template>

<script setup>
import { mainMenus } from "./utils";

import { onBeforeMount, onUpdated, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
// import { BASE_URL } from "./api";
import { useAuth, useFilter, useProject, useSys, useUtils, useWatch } from "./uses";
import utils from "@/utils";

const ROUTER_SEACH_HOME = ["project-detail", "home"];
const ROUTER_SEACH_WATCH = ["watch", "w-project-detail"];

// const active = ref(false);
const utiStore = useUtils();
const filter = useFilter();
const auth = useAuth();
const route = useRoute();
const router = useRouter();
const store = useProject();
const storeWatch = useWatch();
const state = reactive({ suggests: [], isFloat: false });
const uSys = useSys();

function onManage() {
	router.push({ name: "projects" });
}

function onFloatClick(url) {
	if (url) {
		utils.share.openLink(url);
	}
	state.isFloat = false;
}

function onGoMenu(name) {
	router.push({ name });
	uSys.onUpdateTitle();
}

function onSubmitSearch(evt) {
	const _ty = route.name;
	if (ROUTER_SEACH_HOME.includes(_ty)) {
		store.page = 1;
		if (_ty === "project-detail") {
			store.onSetType("all");
		}
		store.onFilter(evt);
		if (_ty === "project-detail") router.push({ name: "home" });
	} else if (ROUTER_SEACH_WATCH.includes(_ty)) {
		storeWatch.page = 1;
		if (_ty === "w-project-detail") {
			storeWatch.onSetType("all");
		}
		storeWatch.onFilter(evt);
		if (_ty === "w-project-detail") router.push({ name: "watch" });
	}
}

const onSearch = debounce(fnData, 800);

function debounce(func, wait) {
	let timeout;

	return function (...args) {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), wait);
	};
}

async function fnData() {
	onSubmitSearch(null);
	// try {
	// 	state.busy = true;
	// 	const data = await publicApi.suggests({ s });
	// 	state.list = data;
	// } catch (error) {
	// 	console.log(error);
	// } finally {
	// 	state.busy = false;
	// }
}

// function onMenu(name) {
// 	if (["watch", "home"].includes(name)) {
// 		let active = name;
// 		if (name === "home") {
// 			active = "active";
// 		}
// 		store.fetchData(name, active, { page: 1 });
// 	}
// 	router.push({ name });
// }
// const suggests = computed(() => [...filter.chains, ...filter.categories, filter.stages]);

// async function onSuggestSearch($event) {
// 	const params = {};
// 	if ($event) {
// 		params.s = $event;
// 	}
// 	// const data = await publicApi.suggests(params);
// 	// state.suggests = data;
// }

// function showSearchModal($bvModal) {
// 	$bvModal.show("modal-srch");
// }

onBeforeMount(() => {
	auth.autoLogin();
});

// const avatar = computed(() => {
// 	let _avatar = auth.user?.avatar;
// 	if (_avatar && _avatar.startsWith("http")) return _avatar;
// 	if (_avatar == "/logo.png") return _avatar;
// 	return BASE_URL + _avatar;
// });
onUpdated(() => {
	utiStore.updateTitle(route?.title || route.meta?.title);
});
</script>

<style lang="scss">
@import "@/assets/app.scss";

.main-layout {
	clear: both;
	width: 100%;
	height: 100%;
	transition: var(--trans-out);
	display: flex;
	flex-direction: column;
	.btn-floating {
		@media only screen and (max-width: 768px) {
			display: none;
		}
		display: flex;
		position: absolute;
		z-index: 99999;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		background-image: url("@/assets/imgs/btn-floating.svg");
		background-size: cover;
		bottom: 3rem;
		right: 1rem;
		cursor: pointer;
		transform: background-image 0.3s ease-out;
		&:hover {
			box-shadow: 0 20px 20px -10px #2d1834;
		}
		&.close {
			background-image: url("@/assets/imgs/close.svg") !important;
			transform: background-image 0.3s ease-out;
			box-shadow: 0 20px 20px -10px #2d1834;
		}
	}
	.frame-floating {
		display: none;
		position: absolute;
		background: #2d1834; //linear-gradient(101.61deg, rgba(255, 189, 213, 0.2) -14.72%, rgba(220, 114, 255, 0.2) 66.97%);
		bottom: 6rem;
		right: 1.25rem;
		border: 1px solid #ffffff66;
		z-index: 100000;
		border-radius: 0.5rem;
		padding: 1rem;
		gap: 1rem;
		&.active {
			display: flex !important;
			flex-direction: column;
			transform: var(--trans-out);
			transform: all 0.3s ease-out;
		}
		.ac-item {
			display: flex;
			align-items: center;
			flex-direction: row;
			cursor: pointer;
			gap: 0.5rem;
			&.border-buttom {
				border-bottom: 1px solid #ffffff1a;
				padding-bottom: 1rem;
			}
			.logo {
				height: 1.5rem;
				width: 1.5rem;
			}
			.title {
				font-family: var(--font-family);
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				align-items: center;
				color: #ffffff;
			}
		}
	}
	.ido-nav-bar {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		align-items: end !important;
		z-index: var(--nav-zindex);
		height: var(--nav-height);
		.btn-collapse {
			span {
				color: var(--text-color-primary);
			}
		}
		.brand-name {
			min-width: calc(var(--nav-sliderbar) - 2rem);
			margin-right: 0;
			.logo {
				width: auto;
				height: var(--nav-height-logo);
				margin-bottom: -1rem;
				margin-left: 1rem;
			}
		}
		#nav-collapse {
			.main-menu {
				display: flex;
				.menu-item {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 1rem;
					gap: 1rem;
					height: 43px;
					border-radius: 0.5rem;
					flex: none;
					flex-grow: 0;
					// transition: var(--trans-out);
					transition: all 0.3s ease-in-out;
					a {
						color: rgba(255, 255, 255, 0.65);
						font-size: 1.125rem;
						font-weight: 500;
					}
					&.active {
						background: linear-gradient(101.61deg, #ff1f6f -14.72%, #870ab1 66.97%);
						a {
							color: #fff;
							font-size: 1rem;
							font-weight: 700;
						}
					}
				}
			}

			.group-search {
				transition: var(--trans-out);
				display: flex;
				gap: 0.75rem;
				.box-search {
					transition: var(--trans-out);
					cursor: text;
					.icon-search {
						transition: var(--trans-out);
						cursor: text;
						.input-group-text {
							border-top-left-radius: 1rem;
							border-bottom-left-radius: 1rem;
							background: #61455c;
							border-color: transparent;
							transition: var(--trans-out);
							border-right-width: 0px;
							border-color: #988695;
						}
					}
					.input-search {
						border-top-right-radius: 1rem;
						border-bottom-right-radius: 1rem;
						border-left-width: 0px;
						border-color: #988695;
						outline: none;
						background: #61455c;
						transition: var(--trans-out);
						color: #907c8d;
						&.show {
							transition: var(--trans-out);
							display: unset;
						}
						&::placeholder {
							color: #907c8d;
						}
						&::-webkit-search-cancel-button {
							background: #61455c;
						}
						&:focus {
							outline: none;
							border-left-width: 0px;
							border-left-width: 0px;
							box-shadow: none;
							background-color: #61455c;
						}
						&:before,
						&:active {
							outline: none;
							// border: none;
							background-color: #61455c;
						}
					}
				}

				.ido-icon {
					transition: var(--trans-out);
					border: 1px solid #988695;
					display: flex;
					justify-content: center;
					align-items: center;
					align-self: center;
					border-radius: 50%;
					background: #61455c;
					cursor: pointer;
					width: 2.5rem;
					height: 2.5rem;
					svg {
						color: var(--text-color-primary);
					}
					.ic-user {
						width: 2.1rem;
						height: 2.1rem;
						border-radius: 50%;
					}
				}
			}
		}
	}
	.wrap-layout {
		flex: 1;
		// background: yellow;
		// width: 100%;
		// height: calc(100vh - var(--nav-height));
		padding-top: 0.5rem;
	}

	@media only screen and (max-width: 768px) {
		.ido-nav-bar {
			height: var(--nav-mheight);
			.brand-name {
				.logo {
					height: var(--nav-mheight-logo);
					width: auto;
					margin-left: 0.5rem;
					margin-bottom: auto;
					margin-top: 0.5rem;
				}
			}
			#nav-collapse {
				background: linear-gradient(101.61deg, #ff1f6f -14.72%, #870ab1 66.97%);
				.group-search {
					display: flex;
					flex-direction: row;
					padding: 1rem;
					justify-content: center;
					gap: 1rem;
					transition: var(--trans-out);
				}
			}
		}
		.wrap-layout {
			margin-left: 1rem;
		}
	}

	@media only screen and (max-width: 992px) {
		.ido-nav-bar {
			height: var(--nav-mheight);
			.brand-name {
				.logo {
					height: var(--nav-mheight-logo);
					width: auto;
					margin-left: 0.5rem;
					margin-bottom: auto;
					margin-top: 0.5rem;
				}
			}
			#nav-collapse {
				background: linear-gradient(101.61deg, #ff1f6f -14.72%, #870ab1 66.97%);
				.group-search {
					display: flex;
					flex-direction: row;
					padding: 1rem;
					justify-content: center;
					gap: 1rem;
				}
			}
		}
	}
}
</style>
