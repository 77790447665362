import Vue from "vue";
import InputTags from "vue3-tags-input";
// Vue.component("ido-nav", () => import("./ido-nav.vue"));
Vue.component("login-form", () => import("./login-form.vue"));
Vue.component("i-dropdown", () => import("./drop-down.vue"));
Vue.component("ido-footer", () => import("./ido-footer.vue"));
Vue.component("ido-nav", () => import("./ido-nav.vue"));
Vue.component("tb-loading", () => import("./tb-loading.vue"));
Vue.component("tb-empty", () => import("./tb-empty.vue"));
Vue.component("nav-login", () => import("./nav-login.vue"));
Vue.component("card-research", () => import("./card-research.vue"));
Vue.component("ido-content", () => import("./ido-content.vue"));
Vue.component("nav-left-base", () => import("./navleft-base.vue"));
Vue.component("m-checkbox", () => import("./mcheckbox.vue"));
Vue.component("m-link", () => import("./ido-link.vue"));
Vue.component("ido-search-form", () => import("./search-form.vue"));
Vue.component("privacy-policy", () => import("./privacy-policy.vue"));
Vue.component("terms-and-conditions", () => import("./terms-and-conditions.vue"));
Vue.component("m-lauchpad", () => import("./m-lauchpad.vue"));
Vue.component("social-media-group", () => import("./social-media-group.vue"));
Vue.component("tags-input", InputTags);
